body {
  font-family: "Mulish", sans-serif !important;
  font-weight: 500 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
a {
  font-family: "Mulish", sans-serif !important;
  font-weight: 500 !important;
}


text {
  font-family: "Mulish", sans-serif !important;
  font-weight: 500 !important;
  fill: black;
  font-size: 14px;
}


.rightAlignText {
  text-align: right;
}


.relative {
  position: relative;
}


.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexColumnEvenly {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexRowBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex1 {
  flex: 1;
}



.padding0 {
  padding: 0px;
}
.padding16 {
  padding: 16px;
}
.padding24 {
  padding: 24px;
}

.padding40 {
  padding: 40px;
}
.paddingL16 {
  padding-left: 16px;
}




.MuiAccordionDetails-root p {
  font-weight: 300 !important;
}

.link {
  fill: none;
  stroke: #555;
  stroke-opacity: 0.4;
  stroke-width: 1px;
}

.noUnderline {
  text-decoration: none;
}



.pointer {
cursor: pointer;
}

.btn {
  
  border-radius: 0px;
  background-color: transparent;
  border: 1px solid #333;
  padding: 7px 10px;
}
.btn:hover {
  background-color: #0000000a;
}
.btnHover:hover {
  opacity: 1;
}

.btnNoStyle {
  background-color: transparent;
  border: none;
  padding: 0px !important;
}


.backgroundBlue0-1 {
  background-color: rgba(25, 87, 205, 0.1);
}



.colorBlack1 * {
  color: #171C20 !important;

}


.dialog-text {
  font-weight: 300 !important;
}

.xAxis .tick text {
  fill: black;
}

.y-axis.transparent path.domain {
  stroke: transparent;
}

.grid .tick line {
  stroke: grey;
  stroke-dasharray: 5, 10;
  opacity: 0.7;
}

.grid path {
  stroke-width: 0;
}

.node circle {
  fill: #999;
}
.node--internal circle {
  fill: #555;
}
.node--internal text {
  font-size: 16px;
  text-shadow: 0 2px 0 #fff, 0 -2px 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff;
}
.node--leaf text {
  fill: white;
}
.ballG text {
  fill: white;
}

/* .shadow {
  -webkit-filter: drop-shadow(-1.5px -1.5px 1.5px #000);
  filter: drop-shadow(-1.5px -1.5px 1.5px #000);
} */




.popper-main *,
.public-site-popper-main * {
  font-size: 20px;
}

.popper-main .MuiPaper-rounded {
  border-radius: 8px;
}

.filterSummary.container {
  /* padding: 20px; */
  min-width: 250px;
  max-height: 50vh;
  overflow: scroll;
}
.filterSummary p,
.filterSummary span {
  font-size: 16px !important;
  line-height: 24px;
}
.filterSummary .headerWrapper {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 16px;
}
.filterSummary .filterWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 24px;
}
.filterSummary .filterSection {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
}
.filterSummary .filterSection p {
  margin: 0px 0px 4px;
}

.filterSummary .chipsContainer {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.filterSummary .chip {
  width: fit-content;
  background-color: #F3F3F3;
  /* padding: 8px 16px; */
}
.filterSummary .chip span {
  padding: 4px 12px;
}
.filterSummary .saveButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.publicSiteLayout .filterSummary.contianer {
  padding: 0px;
}



.filterDropDown button.MuiButton-containedPrimary {
  border-radius: 100px !important;
  background-color: #000 !important;
  text-transform: none !important;
  box-shadow: none !important;
  padding: 10px 24px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}




/* MAP */

#mapid {
  height: 180px;
}

.mapboxgl-ctrl-compass {
  display: none !important;
}

.mapWrapper {
  position: relative;
  width: 100%;
}

.mapInfoDialog #customized-dialog-title {
  padding: 24px;
}

.mapInfoDialog .MuiDialogContent-root,
.mapInfoDialog .MuiDialogActions-root {
  padding: 0px 24px 24px;
}

.mapInfoDialog .MuiButton-textPrimary {
  color: #1957CD;
}



.mapLegendWrapper {
  position: relative;
  left: 30px;
  width: 300px;
}

.mapLegendWrapper .legendButton {
  border-radius: 62px !important;
  text-transform: none;
  padding: 8px 16px;
}
.mapLegendWrapper .legendButton .MuiButton-label span {
  font-weight: 700 !important;
  margin-right: 10px;
}

.mapLegendWrapper .MuiPaper-rounded {
  border-radius: 8px;
}


.maplegend {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.maplegend.hasCheckboxes {
  padding: 16px 16px 12px !important;
}



.maplegend .coloredDiv {
  background: linear-gradient(#e9f6fc, #93d6f1, #28ade3, #186788, #0c3344, #000000);
  height: 120px;
   width: 30px;
  margin-right: 5px;
}
.maplegend .coloredDiv.fcdTraffLoc {
  background: linear-gradient(#e9f6fc, #a2dbf2, #63c9f2, #28ade3, #186788, #0c3344, #000000);
}
.maplegend .coloredDiv.imbGrowthRate {
  background: linear-gradient(#297C63, #AEE0C6, #e9f6fc,  #28ade3, #000000);
}

.maplegend div span,
.maplegend .colorBlock {
  border-radius: 50%;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}

.maplegend .colorBlock {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border-radius: 8%;
}

.maplegend p {
  font-weight: 700;
  margin: 0px 0px 8px;
}

.maplegend .flexColumnEvenly p {
  font-size: 10px;
  font-weight: 600;
  margin: 0px;
}



.casemapinfo {
  background-color: #fff;
  border-radius: 16px;
  top: 80px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 24px;
  /* display: flex; */
  position: absolute;
  left: 100px;
  z-index: 1;
  width: 225px;
  min-height: 90px;
  height: auto;
}

.casemapinfo.screeningsWidth {
  width: 285px;
}

.casemapinfo.imbGrowthRate {
  width: 235px;
}


.casemapinfo h2,
.casemapinfo .totalText {
  font-family: "Mulish", serif !important;
  font-size: 20px;
  line-height: 31px;
  font-weight: 700 !important;
  margin: 0px 0px 16px;
}

.casemapinfo p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 8px 0px 0px;
}

.casemapinfo h4 {
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 600;
  margin: 0px 0px 0.5em !important;
}


.mapInfoBoxContainer {
  position: relative !important;
}

.casemapinfo.reportMapInfoBox h2 {
   font-size: 14px !important;
   line-height: 24px !important;
}
.casemapinfo.reportMapInfoBox h4 {
   font-size: 12px !important;
   line-height: 12px !important;
   margin-bottom: 10px !important;
}
.casemapinfo.reportMapInfoBox p,
  .casemapinfo.reportMapInfoBox span {
   font-size: 11px !important;
   line-height: 11px !important;
   margin-bottom: 10px !important;
}



.MuiDialog-paperWidthSm,
.MuiDialog-paperWidthMd,
.MuiDialog-paperWidthLg,
.MuiDialog-paperWidthXl {
  width: 1100px !important;
}

.agegender .tick text,
.racegender .tick text {
  font-size: 10px !important;
}

.modalScroll::-webkit-scrollbar {
  width: 20px; /* make scrollbar transparent */
}

#report-wrapper .MuiTableContainer-root {
  /* container for tables */
  width: fit-content;
  min-width: 60vw !important;
}
#report-wrapper .tableHoverDonutChart table.legend {
  min-width: auto !important;
}

.report-filter-titles p {
  font-weight: 300 !important;
  margin-left: 20px;
}

.report-accordion a button {
  margin-top: 15px !important;
}


/* Report page */
.html2canvas-container {
  /* updating how much of the screen is viewable by the html2canvas at a time */
  width: 100vw !important;
  height: 3000px !important;
}




.yearSliderText {
  margin: 0px; 
  font-weight: 500 !important;
}

.yearSlider {
  width: 140px !important;
  padding: 5px 0px !important;
  color:#24a1d5  !important; /* #28ade3 */
}
.yearSlider .MuiSlider-rail,
.yearSlider .MuiSlider-track {
  height: 5px !important;
  border-radius: 5px !important;
}
.yearSlider .MuiSlider-rail {
  color: #d6d6d6;
  border: 1px solid #919191;
  /* color: #efefef; */
  /* border: 1px solid #b2b2b2; */
}
.yearSlider .MuiSlider-track {
  border: 1px solid #24a1d5;
}
.yearSlider .MuiSlider-thumb {
    width: 16px !important;
    height: 16px !important;
}
.yearSlider .MuiSlider-thumb:hover {
  box-shadow: none !important;
}
.yearSlider .MuiSlider-thumb::after {
  display: none !important;
}

.yearSlider .MuiSlider-valueLabel {
  display: none !important;
}



.twoSingleCards {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.twoSingleCards .single-card,
.twoSingleCards .singleCard  {
  height: 100%;
}
.twoSingleCards .singleCard  {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.twoSingleCards .MuiCardContent-root {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}


/* Create the checkmark/indicator (hidden when not checked) */
.chartCheckboxContainer .chartCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Style the checkmark/indicator */
.chartCheckboxContainer .chartCheckmark:after {
    left: 4px;
    top: 0px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);
}
/* Show the checkmark when checked */
.chartCheckboxContainer .chartCheckboxes:checked ~ .chartCheckmark:after {
  display: block;
}
.chartCheckboxContainer .chartCheckboxes:checked ~ .chartCheckmark.hideCheckMark:after {
  display: none;
}

/* public site checkmark */
.publicSiteLayout .chartCheckboxContainer .chartCheckmark:after {
  /* left: 5.5px !important; */
  top: -1px !important;
  /* width: 4px !important; */
  /* height: 10px !important; */
}



.filterDropDown .chartCheckboxContainer {
  font-size: 16px;
}

.filterDropDown .chartCheckboxContainer .chartCheckmark {
  bottom: 0;
  margin: auto 0;
}

.axislabel {
  font-size: 12px;
  font-weight: 700 !important;
}

.pieGraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 14px;
}


#report-wrapper .fields {
  margin-bottom: 10px;
}


.cursorDefault {
  cursor: default;
}


.noHoverOpacity {
  opacity: 1 !important;
}



.filterButton {
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 16px !important;
}
.filterButton .MuiButton-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: none;
}

.filterButton.listMenu {
  min-width: 0px;
  margin: 0px 16px;
  padding: 0px;
}
.filterButton.listMenu img {
  margin-top: 0px;
}

.filterButton .carotDown {
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.filterButton.legendButton {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 0px;
}

.displaySwitch {
  padding: 12px 8px !important;
}
.displaySwitch .MuiSwitch-thumb {
  background-color: #44CEA5;
  box-shadow: none !important;
}

.displaySwitch .MuiSwitch-track {
  background-color: #B4EBDB;
}


.mapboxgl-popup {
  max-width: 260px;
}
.mapboxgl-popup .mapboxgl-popup-content {
  border-radius: 8px !important;
  padding: 16px !important;
}
.mapContainer.report .mapboxgl-popup-content h3 {
  /* report page */
  font-weight: 500 !important;
}
.mapboxgl-popup-content h3 {
  font-size: 14px; 
  line-height: 17px; 
  font-weight: 700 !important; 
  margin: 0px 0px 8px;
}

.mapboxgl-popup-content p {
  font-size: 12px; 
  line-height: 14.5px; 
  font-weight: 600 !important; 
  margin: 0px 0px 8px;
}





.reportMapInfoBox {
  width: auto;
  min-width: 120px;
  max-width: 210px !important;
  height: auto;
  min-height: auto !important;
  font-size: 8px !important;
  top: 10px !important;
  left: 5px !important;
  padding: 12px;
  /* border-radius: 3px; */
}

.reportMapInfoBox.imbGrowthRate {
  width: auto !important;
}

.reportMapInfoBox h2 {
  font-size: 12px;
  margin-bottom: 5px !important;
}

.reportMapInfoBox h4,
.reportMapLegendWrapper .maplegend .flexColumnEvenly p,
.reportMapLegendWrapper .maplegend p {
  font-size: 9px !important;
  line-height: 13px;
}





.reportMapLegendWrapper {
  position: absolute;
  width: 180px;
  /* height: calc(100% - 35px); */
  /* height: 365px; */
  bottom: 35px;
  left: 5px;
  display: flex;
}

.reportMapLegendWrapper .maplegend {
  padding: 12px !important;
}

.reportMapLegendWrapper .maplegend .coloredDiv {
   height: 80px;
   width: 20px;
}
.reportMapLegendWrapper .maplegend .coloredDiv.imbGrowthRate {
   height: 90px;
}

.reportMapLegendWrapper .maplegend p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.reportMapLegendWrapper .filterButton img {
  width: 16px;
  /* padding-left: 13px;
  padding-right: 13px; */
}

.reportMapLegendWrapper .maplegend .MuiSvgIcon-root,
.reportMapLegendWrapper .maplegend div span,
.reportMapLegendWrapper .maplegend .colorBlock {
  width: 15px !important;
  height: 15px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.reportMapLegendWrapper .maplegend .colorBlock {
  width: 10px !important;
  height: 10px !important;
}

.reportMapLegendWrapper .maplegend label {
  font-size: 9px !important;
  line-height: unset !important;
}


.reportMapLegendWrapper .legendButton {
  height: auto;
  align-self: flex-end;
  padding-left: 13px;
  padding-right: 13px;
}
.reportMapLegendWrapper .legendButton .MuiButton-label {
  font-size: 10px !important;
}

.maplegend.report .chartCheckboxContainer {
  padding: 0px !important;
  margin-left: 0px !important;
}

.maplegend.report .chartCheckboxContainer .chartCheckmark:after,
.reportMapLegendWrapper .chartCheckboxContainer .chartCheckmark:after {
  width: 6px !important;
  height: 11px !important;
}





.singleCard {
  padding: 32px 32px 10px;
}

.chartTableDialog .MuiPaper-rounded,
.chartInfoDialog .MuiPaper-rounded,
.singleCard {
  border-radius: 16px !important;
}



.MuiCardHeader-title {
  font-size: 28px;
  font-weight: 700 !important;
}

.MuiButton-root {
  text-transform: none;
}


.mapInfoWrapper .MuiButton-root {
  min-width: 0px;
  padding: 8px;
  border-radius: 8px;
}



/* Make room for colored banner */
/* .loggedInBanner {
  z-index: 1000;
  width: 100vw;
  height: 54px;
  position: fixed;
  top: 0;
  background: linear-gradient(90deg, #1957CD 0%, #44CEA5 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 64px;
  color: #fff;
}
.loggedInBanner button {
  display: flex;
  background-color: transparent;
  border: none;
}
.loggedInBanner button svg {
  font-size: 22.29px;
  color: #fff;
}
.loggedInBanner button svg  path {
  stroke: none;
}

.bannerSeparater {
  position: relative;
  top: 54px;
  height: calc(100vh - 54px) !important;
}
.bannerSeparater .appBarHeader,
.bannerSeparater .MuiAppBar-positionFixed {
  top: 54px;
}
.bannerSeparater .primaryDrawer,
.bannerSeparater .primaryDrawer .MuiDrawer-paper  {
  top: 54px;
  height: calc(100% - 54px);
}

.bannerSeparater .chartDrawer .MuiPaper-root {
  margin-top: 119px;
}

.bannerSeparater #dashboard .contentDiv {
  height: 100%;
}
.bannerSeparater #dashboard .mapInfoWrapper {
  top: 134px;
}
.bannerSeparater #dashboard .mapWrapper {
  height: calc(80vh - 54px) !important;
}
.bannerSeparater #dashboard .mapContainer {
  height: calc(100vh - 118px) !important;
}
#dashboard .mapboxgl-canvas {
  height: calc(100vh - 64px) !important;
}
.bannerSeparater #dashboard .mapboxgl-canvas {
  height: calc(100vh - 118px) !important; 
} 
*/



.mapboxgl-ctrl-group:not(:empty) {
  border-radius: 8px;
  box-shadow: none;
}
.mapboxgl-ctrl-group > button {
  width: auto;
  height: auto;
  padding: 8px;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  width: 24px;
  height: 24px;
  background-image: url('./Assets/Images/map-zoom-in-icon.png');
  background-size: contain;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  width: 24px;
  height: 24px;
  background-image: url('./Assets/Images/map-zoom-out-icon.png');
  background-size: contain;
}



.newsletterSubscribeClose {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}





.fontMulish,
.fontMulish * {
  font-family: "Mulish", sans-serif !important;
}

.fontLora {
  font-family: "Lora", serif !important;
}


.fontSize2em { 
  font-size: 2em !important;
}
.fontSize1-75em {
  font-size: 1.75em !important;
}
.fontSize1-5em {
  font-size: 1.5em !important;
}
.fontSize1-25em {
  font-size: 1.25em !important;
}
.fontSize1em { 
  font-size: 1em !important;
}

.fontSize24,
.fontSize24 h2 {
  font-size: 24px !important;
}
.fontSize16 {
  font-size: 16px !important;
}
.fontSize12 {
  font-size: 12px !important;
}
.fontSize10 {
  font-size: 10px !important;
}
.fontSize20 {
  font-size: 20px;
}
.fontSize32 {
  font-size: 32px;
}


.lineHeight1-2em {
  line-height: 1.2em;
}
.lineHeight1-3em {
  line-height: 1.3em;
}
.lineHeight1-4em {
  line-height: 1.4em;
}
.lineHeight1-5em {
  line-height: 1.5em;
}
.lineHeight24 {
  line-height: 24px;
}
.lineHeight25 {
  line-height: 25px;
}
.lineHeight41 {
  line-height: 41px;
}


.fontWeight400,
.fontWeight400 * {
  font-weight: 400 !important;
}
.fontWeight500,
.fontWeight500 * {
  font-weight: 500 !important;
}
.fontWeight600,
.fontWeight600 * {
  font-weight: 600 !important;
}
.fontWeight700,
.fontWeight700 * {
  font-weight: 700 !important;
}



.margin0 {
  margin: 0px !important;
}
.marginT0 {
  margin-top: 0px !important;
}
.marginT30 {
  margin-top: 30px;
}
.marginB8 {
  margin-bottom: 8px !important;
}
.marginB14 {
  margin-bottom: 14px !important;
}
.marginB16 {
  margin-bottom: 16px !important;
}
.marginB24 {
  margin-bottom: 24px !important;
}
.marginB40 {
  margin-bottom: 40px !important;
}

.marginB0-5em {
  margin-bottom: 0.5em !important;
}
.marginB0-57em {
  margin-bottom: 0.57em !important;
}
.marginB0-66em {
  margin-bottom: 0.66em !important;
}
.marginB1em {
  margin-bottom: 1em !important;
}



.borderRadius8 {
  border-radius: 8px !important
}
.borderRadius16 {
  border-radius: 16px !important
}


.addRadius24 .MuiPaper-rounded {
  border-radius: 24px !important
}